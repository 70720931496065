@import '../partials';
@import 'node_modules/slick-carousel/slick/slick.scss';

$slider-arrow-width: 44px;

.block-event-slider {
  position: relative;

  &__wrapper {
    position: relative;
    max-width: rem(1920);
    margin: 0 auto;
  }

  .slick-slide:first-child {
    .event-teaser__info {
      padding: rem(26) rem(88);

      @include bp(xs2) {
        padding: rem(26) rem(44);
      }

      @include bp(sm) {
        padding: rem(56);
      }
    }
  }

  .event-teaser {
    &--tall {
      @include ratio-box(332, 314);

      // overriding slick slider inline styles
      width: calc(100% + #{$slider-arrow-width}) !important;
      display: block !important;
      background: $gunmetal;
      color: $white;
      position: relative;

      @include bp(xs2) {
        @include ratio-box(480, 660);

        width: 100% !important;
      }

      .event-teaser__info {
        @include overlay-gradient-slight-tall;
      }
    }

    &__info {
      padding: rem(26) rem(88) rem(26) rem(108);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-transform: uppercase;

      @include bp(xs2) {
        padding: rem(26) rem(44);
      }

      @include bp(sm) {
        padding: rem(56);
      }
    }

    &--endcap {
      @include ratio-box(332, 314);

      color: $white;
      background: $gunmetal url("../DocumentDefault/Assets/logo-background.svg") no-repeat (bottom / 100% auto);
      position: relative;

      // overriding slick slider inline styles
      width: calc(100% + #{$slider-arrow-width}) !important;
      display: block !important;

      @include bp(xs2) {
        @include ratio-box(480, 660);

        width: 100% !important;
      }

      .event-teaser__info {
        padding: rem(26) rem(92) rem(26) rem(112);

        @include bp(xs2) {
          padding: rem(26) rem(46);
        }

        @include bp(sm) {
          padding: rem(62);
        }
      }
    }

    &__endcap-title {
      font-size: rem(28);
      line-height: 1.04;
      font-weight: $font-weight-normal;
      margin: 7px 0;

      @include bp(sm) {
        font-size: rem(32);
      }

      @include bp(lg) {
        font-size: rem(54);
      }
    }

    &__endcap-text {
      font-family: $sans-serif-body-font;
      font-weight: $font-weight-normal;
      line-height: 1.64;
      font-size: rem(18);
      text-transform: none;

      @include bp(lg) {
        font-size: rem(22);
      }
    }

    &__endcap-link {
      font-size: rem(16);
      color: $white;
      font-weight: $font-weight-heavy;
      display: flex;

      &::after {
        width: rem(10);
        height: rem(16);
      }

      @include bp(sm) {
        font-size: rem(18);
      }
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    &__tag {
      font-size: rem(18);
      font-weight: $font-weight-normal;
      margin-bottom: rem(24);
    }

    &__title {
      font-size: rem(32);
      line-height: 0.88;
      font-weight: $font-weight-heavy;
      margin-bottom: rem(11);
      color: $white;
    }

    &__date {
      font-size: rem(18);
      font-weight: $font-weight-heavy;
    }
  }

  &__title-flag {
    position: absolute;
    top: 0;
    left: 0;
    background: $green;
    color: $black;
    font-size: rem(14);
    text-transform: uppercase;
    padding: rem(14) rem(10) rem(7);
    width: rem(71);
    left: rem(26);
    font-weight: $font-weight-heavy;

    @include bp(sm) {
      left: rem(42);
      font-size: rem(18);
      padding: rem(32) rem(15) rem(20);
      width: rem(94);
    }

    &::after {
      content: "";
      top: 100%;
      left: 0;
      position: absolute;
      -webkit-transform: rotate(360deg);
      border-width: rem(47) rem(71) 0 0;
      border-style: solid;
      border-top-color: $green;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;

      @include bp(sm) {
        border-width: rem(63) rem(94) 0 0;
      }
    }
  }

  .slick-slide {
    transform: translateX(-$slider-arrow-width);

    @include bp(xs2) {
      transform: none;
    }
  }

  .slick-arrow {
    text-align: center;
    border-radius: 0;
    background: $black;
    right: 0;
    padding: 0;
    width: rem(44);
    height: rem(44);
    border: none;
    color: $green;
    position: absolute;
    top: calc(50% - 22px);
    z-index: 1;
    cursor: pointer;
    font-size: 0;
    line-height: 0;

    i {
      width: 100%;
      font-size: 25px;
      line-height: 25px;
    }

    @include bp(sm) {
      width: rem(64);
      height: rem(64);
      top: calc(50% - 32px);
    }

    &.slick-prev {
      right: auto;
      left: 0;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
}
